<template>


  <v-layout>
     
    <appbar></appbar>
   

    <!-- Sizes your content based upon application components -->
    <v-main>
        <!-- Provides the application the proper gutter -->
        <v-container fluid>
          
        </v-container>
    </v-main>

    <v-footer app>
        <!-- -->
    </v-footer>
  </v-layout>
</template>

<script>
// import { helpers } from 'gmap-vue';
// const { googleMapsApiInitializer } = helpers;
import appbar from '../components/appbars/AppBarCompany.vue'

  export default {
    name: 'Company',
    message:'',

    components: {
      appbar,
     
    },

    data () {
        return {
       }
    },    

    computed: {

        user(){
            return this.$store.state.auth.user
        },

        routeParams(){
            return this.$route.params
        },

        company() {
            return this.$store.state.app.actCompany.meta
        },
    },

    methods:{ 

        getCompanyById(uid){
            let result = [this.user.linkedCompanies[0]]
            if(uid)
                result = this.user.linkedCompanies.filter(obj => {return obj.uid === uid})

            if(result.length < 1)
                return {id:undefined}
            else            
                return result[0]; 
        }, 

        setCompanyById(uid){
            console.debug('setCompanyById', uid)
            const res = this.getCompanyById(uid)
            if(res){
                this.$store.commit("app/setActCompany", {meta:res})
                
                this.refresh(res)
            }
        },

        refresh(comp){     
            console.debug('refresh', comp);    
             if(comp){  

                this.$store.dispatch("auth/changeLastCompany", comp.uid) 
                
                this.$store.dispatch("app/getApiSetStore", {path:"api/company/menu", param:{store:"menu", id:comp.id}})
                .then( ()=>{    
                    return this.$store.dispatch("app/getApiSetStore", {path:"api/company/apikeys", param:{store:"apikeys", id:comp.id}})
                }).then( ()=>{ 
                    this.$router.push({ name: 'Company' , params: { uid: comp.uid} })   
                }).catch((error)=>{
                    console.error(error);
                })

            }
        },

    },   

    mounted() {
        console.log('mounted') 
        let uid = undefined
        if(!this.$route.params.uid){
            
            if(this.user.last_company)
                uid = this.user.last_company
            else if (this.user.linkedCompanies.length > 0)
                uid = this.user.linkedCompanies[0].uid
        }
        else
            uid = this.$route.params.uid 
            
            
        if(uid)
            this.setCompanyById(uid)

        
    },

    watch: { 
        company(newVal) { // watch it
            console.debug('Prop changed: company', newVal); 
                // if(newVal.uid) 
                //     window.location.reload()  
           
        },
        
        routeParams(newVal) { // watch it
            console.debug('Prop changed: route params', newVal.uid);
            
           

        }
    }    

}
</script>
